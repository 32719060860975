const { hostname } = window.location;
const dotTLD = hostname.lastIndexOf('.');
const dotSLD = dotTLD ? hostname.lastIndexOf('.', dotTLD - 1) : -1;

export const COOKIE_DOMAIN = hostname.slice(dotSLD);

export const SERVER_URL = new URL(
  dotSLD > 0 ? window.origin.replace(hostname, hostname.slice(dotSLD + 1)) : window.origin
);

export const StorageKey = {
  ephemeralCookie: 'ephemeral_login',
  flow: 'flow',
  rememberMe: 'rememberMe',
} as const;
