import { z } from 'zod';

/**
 * Cookie used to pass OAuth grant + userinfo to the SPA that requested auth from the login service.
 * TODO: will auth0 let us avoid this by doing our own auth'z code flow? or can be just build our own inside IAM?
 */
export interface EphemeralCookie {
  grant: {
    access_token: string;
    expires_in: number;
    scope: string;
  };
  userinfo: {
    email: string;
    name: string;
    sub: string;
  };
}

/**
 * Session-storage state for the login flow.
 */
export interface Flow {
  referrer?: string;
  state?: string;
}

/**
 * Location search parameters for the OAuth flow.
 */
export interface FlowSearchParams {
  code: string;
  state: string;
}

/**
 * Ephemeral cookie used to convey login information between SPAs.
 *
 * TODO: share this somewhere (ux-common?) so apps can validate and parse cookie contents.
 */
export const EphemeralCookieSchema = z.object({
  grant: z
    .object({
      access_token: z.string(),
      expires_in: z.number(),
      scope: z.string(),
    })
    .optional(),
  userinfo: z
    .object({
      email: z.string(),
      name: z.string(),
      sub: z.string(),
    })
    .optional(),
});
