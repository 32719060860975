import { RandomBackground } from '@/components/background';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Outlet, createRootRoute } from '@tanstack/react-router';
import { queryClient } from '../singletons';

function Root() {
  return (
    <QueryClientProvider client={queryClient}>
      <RandomBackground>
        <Outlet />
        <footer className="fixed bottom-0 w-full text-center my-7 text-sm">
          © 2024 CrossnoKaye Inc. All rights reserved. <a href="">Privacy Policy</a> |{' '}
          <a href="">Terms of Service</a>
        </footer>
      </RandomBackground>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export const Route = createRootRoute({
  component: Root,
});
