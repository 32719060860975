import type { CookieSerializeOptions } from 'cookie';
import * as cookie from 'cookie';
import React from 'react';
import type { z } from 'zod';

type SetStateCallback<S> = (val: S) => S;

function isSetStateCallback<S>(value: S | SetStateCallback<S>): value is SetStateCallback<S> {
  return typeof value === 'function';
}

/**
 * Persist a value to storage, or remove it if value is undefined.
 */
function persistStorage(storage: Storage, key: string, value: PersistentValue) {
  if (value !== undefined) {
    storage.setItem(key, value);
  } else {
    storage.removeItem(key);
  }
}

/**
 * Range of types that can be safely serialized for cookie or storage state.
 */
type PersistentValue = string | undefined;

/**
 * A Storage implementation that uses cookies.
 */
export class CookieStorage implements Storage {
  options: CookieSerializeOptions;

  constructor(options: CookieSerializeOptions) {
    this.options = options;
  }
  [name: string]: any;

  get length(): number {
    return Object.keys(cookie.parse(document.cookie)).length;
  }

  clear(): void {
    Object.keys(cookie.parse(document.cookie)).forEach((key) => {
      document.cookie = cookie.serialize(key, '', {
        ...this.options,
        expires: new Date(),
        maxAge: -1,
      });
    });
  }

  getItem(key: string): string | null {
    return cookie.parse(document.cookie)[key] ?? null;
  }

  key(index: number): string | null {
    const key = Object.keys(cookie.parse(document.cookie))[index];
    return this.getItem(key);
  }

  removeItem(key: string): void {
    document.cookie = cookie.serialize(key, '', {
      ...this.options,
      expires: new Date(),
      maxAge: -1,
    });
  }

  setItem(key: string, value: string): void {
    document.cookie = cookie.serialize(key, value, this.options);
  }
}

/**
 * Keep state in local or session storage (or in a cookie, with the CookieStorage adapter class)
 *
 * For simple data types that cleanly round-trip to JSON, provide a type parameter.
 *
 * For complex data types e.g. things that contain a `Date` field, provide a Zod
 * schema as the final runtime parameter to validate and parse the data.
 *
 * Note that persistence is not guaranteed until the setter's promise resolves!
 * Make sure to `await` it before navigating away from the application.
 */
export function usePersistentState<T>(s: Storage, key: string, schema?: z.ZodObject<any>) {
  const [observableValue, setObservableValue] = React.useState<T | undefined>((): T | undefined => {
    const serialized = s.getItem(key);
    if (serialized) {
      let value = JSON.parse(serialized);
      if (schema) {
        value = schema.parse(value);
      }
      return value as T;
    }
  });

  const setValue = React.useCallback(
    (value: T | undefined | SetStateCallback<T | undefined>) =>
      new Promise<void>((resolve) =>
        setObservableValue((oldValue) => {
          const newValue = isSetStateCallback(value) ? value(oldValue) : value;
          persistStorage(s, key, JSON.stringify(newValue));
          resolve();
          return newValue;
        })
      ),
    [s, key]
  );

  return [observableValue, setValue] as const;
}
