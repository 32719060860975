import loginLogo from '@/assets/images/login-logo.svg';
import { DiscoveryForm, PasswordForm } from '@/components/forms';
import { StorageKey } from '@/constants';
import { useEphemeralCookie, useFlow, useNavigateExternally } from '@/hooks';
import { router, sdk } from '@/singletons';
import { usePersistentState } from '@repo/shared';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useCallback, useEffect, useState } from 'react';

const Page = () => {
  const navigate = useNavigate();
  const navigateExternally = useNavigateExternally();

  const [flow, setFlow] = useFlow();

  const [rememberMe, setRememberMe] = usePersistentState<string>(
    localStorage,
    StorageKey.rememberMe
  );

  const [, setCookie] = useEphemeralCookie();

  // Track SSO flow progress
  useEffect(() => {
    if (!flow?.state) {
      setFlow({
        referrer: document.referrer,
        state: crypto.randomUUID(),
      });
    }
  }, [flow?.state, setFlow]);

  const [gatherPassword, setGatherPassword] = useState<undefined | { email: string }>();

  // Perform SSO discovery; then, either initiate SSO flow, or tell login form to gather password.
  // Eventually, callback page will finalize SSO and redirect to post-login.
  const discoverEmail = useCallback(
    async (email: string) => {
      const { state } = flow ?? {};
      const result = await sdk.discover(email, state);
      if (!result?.url) {
        setGatherPassword({ email });
      }
      if (result?.url) {
        const startURL = new URL(result.url);
        const endURL = new URL(window.location.href);
        endURL.pathname = router.buildLocation({ to: '/session/callback' }).pathname;
        startURL.searchParams.set('redirect_uri', endURL.toString());
        navigateExternally({ to: startURL.toString() });
      }
    },
    [flow, navigateExternally]
  );

  // Perform password login; then, redirect to post-login.
  const loginWithPassword = useCallback(
    async (formEmail: string, password: string) => {
      const { accessToken, expiresIn, scope } = await sdk.grantWithPassword(formEmail, password);
      // TODO: redirect properly (seems like showUserinfo + setCookie + navigate are shared logic!)
      const { email, name, sub } = await sdk.showUserinfo(accessToken);
      setCookie({
        grant: { access_token: accessToken, expires_in: expiresIn, scope },
        userinfo: { email, name, sub },
      });
      navigate({ to: '/session' });
    },
    [navigate, setCookie]
  );

  return (
    <div className="flex flex-col items-center justify-center h-screen gap-y-12">
      <img src={loginLogo} alt="Login Logo" />
      {gatherPassword ? (
        <PasswordForm
          email={gatherPassword.email}
          onSubmit={(values) => loginWithPassword(gatherPassword.email, values.password)}
        />
      ) : (
        <DiscoveryForm
          defaultEmail={rememberMe ?? ''}
          onSubmit={(values) => {
            if (values.remember) {
              setRememberMe(values.email);
            } else {
              setRememberMe(undefined);
            }
            return discoverEmail(values.email);
          }}
        />
      )}
    </div>
  );
};

export const Route = createFileRoute('/session/new')({
  component: Page,
});
