import loginLogo from '@/assets/images/login-logo.svg';
import { SessionCard } from '@/components/cards';
import { useEphemeralCookie } from '@/hooks';
import { createFileRoute, useNavigate } from '@tanstack/react-router';

const Page = () => {
  const navigate = useNavigate();
  const [cookie, setCookie] = useEphemeralCookie();

  if (!cookie) {
    navigate({ to: '/session/new' });
    return null;
  }

  return (
    <div className="flex flex-col items-center justify-center h-screen gap-y-12">
      <img src={loginLogo} alt="Login Logo" />
      <SessionCard data={cookie.userinfo} onLogout={() => setCookie(undefined)} />
    </div>
  );
};

export const Route = createFileRoute('/session/')({
  component: Page,
});
