import loginLogo from '@/assets/images/login-logo.svg';
import { ErrorCard } from '@/components/cards';
import { useEphemeralCookie, useFlow, useFlowParams, useNavigateExternally } from '@/hooks';
import { FlowSearchParams } from '@/models';
import { sdk } from '@/singletons';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { Loader2 } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';

class MismatchedFlowState extends Error {
  actual: string;
  expected: string;

  constructor(actual: string, expected: string) {
    super('Sign-in flow already completed, timed out, or not yet started');
    this.name = 'MismatchedFlowState';
    this.actual = actual;
    this.expected = expected;
  }
}

class UndefinedFlowState extends Error {
  constructor() {
    super(`No sign-in flow in progress`);
    this.name = 'UndefinedFlowState';
  }
}

type ProtocolError = Error | MismatchedFlowState | UndefinedFlowState;

function Callback() {
  const navigate = useNavigate();
  const navigateExternally = useNavigateExternally();

  const [_, setCookie] = useEphemeralCookie();
  const { code, state } = useFlowParams() ?? {};
  const [flow, setFlow] = useFlow();
  const [loading, setLoading] = useState(true);

  const [error, setError] = useState<ProtocolError | Response | undefined>();

  const gatherError = useCallback(
    (exc: Error | Response) => {
      return !error && setError(exc);
    },
    [error, setError]
  );

  const [ingestedFlowParams, setIngestedFlowParams] = useState<FlowSearchParams | undefined>();
  useEffect(() => {
    if (code && state) {
      setIngestedFlowParams({ code, state });
    }
  }, [code, state]);

  useEffect(() => {
    async function redeemCode() {
      if (!ingestedFlowParams || !loading) {
        return;
      }
      if (!flow?.state) {
        if (!error) {
          gatherError(new UndefinedFlowState());
        }
        return;
      }
      try {
        if (flow.state === ingestedFlowParams.state) {
          const endURL = new URL(window.location.href);
          endURL.search = '';
          const redirectURI = endURL.toString();
          const { accessToken, expiresIn, scope } = await sdk.grantWithCode(
            ingestedFlowParams.code,
            redirectURI
          );
          const { email, name, sub } = await sdk.showUserinfo(accessToken);
          setFlow(undefined);
          // TODO: redirect properly (seems like showUserinfo + setCookie + navigate are shared logic btw password and OIDC!)
          await setCookie({
            grant: { access_token: accessToken, expires_in: expiresIn, scope },
            userinfo: { email, name, sub },
          });
          switch (flow?.referrer) {
            case window.location.href:
            case '':
            case undefined:
            case null:
              navigate({ to: '/session' });
              return;
            default:
              navigateExternally({ to: flow.referrer });
          }
        } else {
          gatherError(new MismatchedFlowState(ingestedFlowParams.state, flow?.state));
        }
      } catch (exc) {
        // TODO: report to sentry
        if (exc instanceof Error || exc instanceof Response) {
          gatherError(exc);
        } else {
          gatherError(new Error('An unknown error occured'));
        }
      } finally {
        setLoading(false);
      }
    }
    redeemCode();
  }, [
    error,
    flow?.referrer,
    flow?.state,
    loading,
    gatherError,
    navigate,
    navigateExternally,
    ingestedFlowParams,
    setCookie,
    setFlow,
  ]);

  return (
    <div className="flex flex-col items-center justify-center h-screen gap-y-12">
      {error ? (
        <ErrorCard error={error} />
      ) : (
        <>
          <img src={loginLogo} alt="Login Logo" />
          {loading ? <Loader2 className="h-1/4 w-1/4 animate-spin" /> : null}
        </>
      )}
    </div>
  );
}

export const Route = createFileRoute('/session/callback')({
  component: Callback,
});
